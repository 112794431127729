$primary: (
  50: #e0e3e9,
  100: #b3bac9,
  200: #808ca5,
  300: #4d5e81,
  400: #263c66,
  500: #00194b,
  600: #001644,
  700: #00123b,
  800: #000e33,
  900: #000823,
  A100: #5f6eff,
  A200: #2c3fff,
  A400: #0017f8,
  A700: #0014df,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #ffffff,
    A700: #ffffff,
  )
);

$accent: (
  50: #fef9e7,
  100: #fbefc3,
  200: #f9e49c,
  300: #f7d974,
  400: #f5d156,
  500: #f3c938,
  600: #f1c332,
  700: #efbc2b,
  800: #edb524,
  900: #eaa917,
  A100: #ffffff,
  A200: #fff7e8,
  A400: #ffe6b5,
  A700: #ffde9c,
  contrast: (
    50: #00194B,
    100: #00194B,
    200: #00194B,
    300: #00194B,
    400: #00194B,
    500: #00194B,
    600: #00194B,
    700: #00194B,
    800: #00194B,
    900: #00194B,
    A100: #00194B,
    A200: #00194B,
    A400: #00194B,
    A700: #00194B,
  )
);


// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$app-primary: mat-palette($primary);
$app-accent: mat-palette($accent);

// The warn palette is optional (defaults to red).
$app-warn: mat-palette($mat-red);

// Create the theme object (a Sass map containing all of the palettes).
$app-theme: mat-light-theme($app-primary, $app-accent, $app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($app-theme);

/* You can add global styles to this file, and also import other style files */

@import "assets/scss/variables";
@import "assets/scss/normalize";

@font-face {
  font-family: 'latoregular';
  src: url('assets/fonts/lato-regular-webfont.woff2') format('woff2'),
  url('assets/fonts/lato-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'latoblack';
  src: url('assets/fonts/lato-black-webfont.woff2') format('woff2'),
  url('assets/fonts/lato-black-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Roboto';
  src: url('assets/fonts/roboto-regular-webfont.woff2') format('woff2'),
  url('assets/fonts/roboto-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}

html, body {
  background: #fafafa;
  min-height: 100vh;
  overscroll-behavior: none;
}

* {
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p, strong, ul, li {
  color: #333;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, strong {
  font-family: latoblack, sans-serif;
  font-weight: 100;
}

h1, h2, h3, h4, h5, h6, p, a {
  color: $color-secondary;
}

p, a, span, ul, li, input, select, option, button, label {
  font-family: latoregular, sans-serif;
  margin: 0;
  padding: 0;
}

ul {
  padding: 0;

  li {
    padding: 3px 0;
  }
}

ul, li {
  list-style-type: none;
}

a {
  text-decoration: none;
}

div {
  box-sizing: border-box;
}

button {
  span {
    font-family: latoblack, sans-serif;
  }
}

.button--forcedSubmit {
  left: -99999px;
  opacity: 0;
  position: absolute;
  top: -99999px;
  visibility: hidden;
}

mat-form-field {
  margin-bottom: 5px;
}

.MySnackBar {

  button {
    //color: #29335C;
    font-family: open_sansbold, sans-serif;
  }

  &.MySnackBar--success {
    background: $color-accent3;
    color: black;

    button {
      color: black;
    }
  }

  &.MySnackBar--error {
    background: $color-error;
    color: white;

    button {
      color: white;
    }
  }

  &.MySnackBar--warning {
    background: $color-primary;
    color: black;

    button {
      color: black;
    }
  }
}

.TakePhotoDialog-wrapper {
  .mat-dialog-container {
    padding: 10px !important;
  }
}

.mat-button-wrapper {
  padding-top: 10px !important;
  padding-bottom: 10px !important ;
}
