$mobile-padding-top: 0;
$mobile-padding-right: 15px;
$mobile-padding-bottom: 30px;
$mobile-padding-left: 15px;
$mobile-xs-max-width: 420px;

$mobile-header-height: 60px;

$color-primary: #f3c938;
$color-primary-smooth: #ffeed6;
$color-primary-smooth2: #EDF2F4;
$color-secondary: #00194B;
$color-accent3: #7EE081;
$color-accent2: #008a6a;
$color-error: #cc3d34;
